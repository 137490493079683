import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link, Message } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Bulk Customer Import" next={{
  name: 'Send Review Requests',
  path: '/send-review-requests'
}}>
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The bulk customer import feature in Simple RM allows you to import customers from other services you use. This is done via a CSV file upload. `}</p>
    <h2 {...{
      "id": "about-csv-files"
    }}>{`About CSV Files`}</h2>
    <p>{`A comma-separated value (CSV) file is a commonly used file structure for transferring data. You can read more about CSV files `}<a target="_blank" href="https://en.wikipedia.org/wiki/Comma-separated_values">{`here`}</a>{`.
CSV files can be generated by a program that holds data or they can be created from scratch using any spreadsheet program.`}</p>
    <p>{`If you wish to export data from an existing program as a CSV, check the help documents of the program that currently holds the customer data for instructions on how to export it as a CSV file.`}</p>
    <Message sx={{
      mb: 4
    }} mdxType="Message">
Note for QuickBooks Online Users. Enabling the direct integration with QuickBooks Online is the recommended method of syncing data with Simple RM.
    </Message>
    <h2 {...{
      "id": "file-structure"
    }}>{`File Structure`}</h2>
    <p>{`Regardless of how you create your CSV file the main thing to ensure is that it has the column names that Simple RM is expecting. The expected column names are listed below. If your CSV file does not currently use the exact same column names you must edit the file prior to doing the import.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Column`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Requirements`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`first_name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The first name of the customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Although depending on your account settings you may not be able to trigger a review request for the customer without setting a first name.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`last_name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The last name of the customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`phone_number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The phone number of the customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of either phone_number or email_address must be set in order to trigger a request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`email_address`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The email address of the customer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`One of either phone_number or email_address must be set in order to trigger a request`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`assume_satisfied`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Specifies if the customer is assumed to be satisfied or not`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional. Acceptable values are yes or no. If not set the customer will get the default assume_satisfied value specified in your account settings`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`trigger_request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When creating this customer in Simple RM you can immediately trigger a request as well`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Optional, acceptable values are yes or no`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "step-by-step"
    }}>{`Step By Step`}</h2>
    <p>{`How to upload a CSV file in Simple RM. These instructions assume your CSV file is already formatted correctly.`}</p>
    <h3 {...{
      "id": "1-go-to-the-page"
    }}>{`1. Go to the page`}</h3>
    <p>{`Go to the `}<a target="_blank" href='https://app.simplereviewmanagement.com/customer-import'>{`customer import page`}</a>{` in the Simple RM account dashboard.`}</p>
    <h3 {...{
      "id": "2-select-file"
    }}>{`2. Select file`}</h3>
    <p>{`Click the “Choose file” button located in the Create New Import section and select your CSV file.`}</p>
    <h3 {...{
      "id": "3-process-file"
    }}>{`3. Process file`}</h3>
    <p>{`Click the “Process file” button. When you do you will see a new import get created. Wait for the “Processing” status to change to “Complete”.`}</p>
    <h3 {...{
      "id": "4-view-results"
    }}>{`4. View results`}</h3>
    <p>{`Once the “Processing” status to changes to “Complete” you will be able to see a report of everything that just happened.`}</p>
    <p>{`You will see a table where each row represents a row from the CSV file that was successfully processed. The structure of that table is as follows.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Customer Id`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Processing Summary`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`The ID of the customer that was created or updated. Click on the ID to view the customer.`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The exact row from the CSV that was processed. As well as a log of how the row was processed.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Processing a CSV file can results in an error that stops the process. Errors can occur part way through processing so it is possible you may see an error message as well as a table of customers that were imported successfully before the error. If an error occurs, follow the instructions in the error message.`}</p>
    <h2 {...{
      "id": "existing-data"
    }}>{`Existing Data`}</h2>
    <p>{`You may be wondering how the customer information in the CSV file will mix with customer information already in your Simple RM account. Data in the CSV file will supplement existing data in SRM. This means if a customer with matching information already exists in SRM then any piece of information that exists in the CSV but not in the existing customer will be added. However, no information that already exists will be overwritten by values that are different in the CSV. For example:`}</p>
    <ul>
      <li parentName="ul">{`If a customer in the CSV has the same `}<inlineCode parentName="li">{`email_address`}</inlineCode>{` as a customer in the system, but a different `}<inlineCode parentName="li">{`first_name`}</inlineCode>{` then no new customer will be created and the `}<inlineCode parentName="li">{`first_name`}</inlineCode>{` of the existing customer will remain unchanged.`}</li>
      <li parentName="ul">{`Furthermore if that same customer in SRM does not have a `}<inlineCode parentName="li">{`last_name`}</inlineCode>{` but the CSV does. The `}<inlineCode parentName="li">{`last_name`}</inlineCode>{` would get added to the customer in SRM as part of the import.
The reason the `}<inlineCode parentName="li">{`last_name`}</inlineCode>{` gets added while nothing happens for the `}<inlineCode parentName="li">{`first_name`}</inlineCode>{` is that the `}<inlineCode parentName="li">{`last_name`}</inlineCode>{` is only adding to the additional information rather than overwriting existing information.`}</li>
    </ul>
    <p>{`Note that because `}<inlineCode parentName="p">{`assume_satisfied`}</inlineCode>{` and `}<inlineCode parentName="p">{`trigger_request`}</inlineCode>{` columns always have a value, those two columns will only ever affect new customers.`}</p>
    <h2 {...{
      "id": "triggering-requests"
    }}>{`Triggering Requests`}</h2>
    <p>{`The optional `}<inlineCode parentName="p">{`trigger_request`}</inlineCode>{` column in the CSV file is a great way to take care of two tasks at once. If you choose to not include this column you can still trigger requests for customers any time on the `}<a target="_blank" href='https://app.simplereviewmanagement.com/customers'>{`customers page`}</a>{`.`}</p>
    <p>{`For optimal results with your review requests try to only send them to customers you have done business with in the last 60 days.`}</p>
    <h2 {...{
      "id": "import-limit"
    }}>{`Import Limit`}</h2>
    <p>{`At this time we are limiting customer imports to 2500 customers. Contact support if you wish to import a CSV file with more than 2500 customers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      